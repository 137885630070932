import React, { useState, useEffect } from 'react';
import IntroPage from './components/IntroPage';
import Questionnaire from './components/Questionnaire';
import Results from './components/Results';
import History from './components/History';
import { saveData, loadData } from './utils';
import { generateEmailContent } from './utils/emailGenerator';

const App = () => {
  const [view, setView] = useState('intro');
  const [results, setResults] = useState(null);
  const [history, setHistory] = useState([]);
  const [emailContent, setEmailContent] = useState('');

  useEffect(() => {
    setHistory(loadData());
  }, []);

  const handleQuestionnaireComplete = (newResults) => {
    const resultsWithScores = {
      ...newResults,
      date: new Date().toISOString().split('T')[0]
    };

    setResults(resultsWithScores);
    const updatedHistory = [...history, resultsWithScores];
    setHistory(updatedHistory);
    saveData(updatedHistory);

    // Generate email content
    const generatedEmailContent = generateEmailContent(resultsWithScores);
    setEmailContent(generatedEmailContent);

    setView('results');
  };

  const handleShareResults = () => {
    window.location.href = `mailto:?subject=Mental Health Questionnaire Results&body=${encodeURIComponent(emailContent)}`;
  };

  return (
    <div className="App">
      {view === 'intro' && (
        <IntroPage onStartQuestionnaire={() => setView('questionnaire')} />
      )}
      {view === 'questionnaire' && (
        <Questionnaire onComplete={handleQuestionnaireComplete} />
      )}
      {view === 'results' && (
        <Results
          results={results}
          onViewHistory={() => setView('history')}
          onNewQuestionnaire={() => setView('questionnaire')}
          onShareResults={handleShareResults}
        />
      )}
      {view === 'history' && (
        <History
          data={history}
          onNewQuestionnaire={() => setView('questionnaire')}
        />
      )}
    </div>
  );
};

export default App;