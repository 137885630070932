import React, { useState } from 'react';

const questions = [
  { id: 'gad1', text: 'Feeling nervous, anxious, or on edge', type: 'gad7' },
  { id: 'gad2', text: 'Not being able to stop or control worrying', type: 'gad7' },
  { id: 'gad3', text: 'Worrying too much about different things', type: 'gad7' },
  { id: 'gad4', text: 'Trouble relaxing', type: 'gad7' },
  { id: 'gad5', text: 'Being so restless that it\'s hard to sit still', type: 'gad7' },
  { id: 'gad6', text: 'Becoming easily annoyed or irritable', type: 'gad7' },
  { id: 'gad7', text: 'Feeling afraid as if something awful might happen', type: 'gad7' },
  { id: 'phq1', text: 'Little interest or pleasure in doing things', type: 'phq9' },
  { id: 'phq2', text: 'Feeling down, depressed, or hopeless', type: 'phq9' },
  { id: 'phq3', text: 'Trouble falling or staying asleep, or sleeping too much', type: 'phq9' },
  { id: 'phq4', text: 'Feeling tired or having little energy', type: 'phq9' },
  { id: 'phq5', text: 'Poor appetite or overeating', type: 'phq9' },
  { id: 'phq6', text: 'Feeling bad about yourself or that you are a failure or have let yourself or your family down', type: 'phq9' },
  { id: 'phq7', text: 'Trouble concentrating on things, such as reading the newspaper or watching television', type: 'phq9' },
  { id: 'phq8', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual', type: 'phq9' },
  { id: 'phq9', text: 'Thoughts that you would be better off dead, or of hurting yourself', type: 'phq9' },
];

const options = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

const Questionnaire = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});

  const handleAnswer = (answerIndex) => {
    const score = answerIndex; // This is already correct as answerIndex is 0-3
    const newAnswers = { ...answers, [questions[currentQuestion].id]: score };
    setAnswers(newAnswers);
    
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const gad7Score = Object.entries(newAnswers)
        .filter(([key]) => key.startsWith('gad'))
        .reduce((sum, [, value]) => sum + value, 0);
      const phq9Score = Object.entries(newAnswers)
        .filter(([key]) => key.startsWith('phq'))
        .reduce((sum, [, value]) => sum + value, 0);

      onComplete({ 
        gad7: gad7Score, 
        phq9: phq9Score, 
        date: new Date().toISOString().split('T')[0],
        answers: newAnswers
      });
    }
  };

  return (
    <div className="question-container">
      <h2>{questions[currentQuestion].text}</h2>
      <div>
        {options.map((option, index) => (
          <button key={index} onClick={() => handleAnswer(index)}>
            {option}
          </button>
        ))}
      </div>
      <p>Question {currentQuestion + 1} of {questions.length}</p>
    </div>
  );
};

export default Questionnaire;