import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const History = ({ data, onNewQuestionnaire }) => {
  return (
    <div className="results-container">
      <h2>Your History</h2>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="gad7" stroke="#8884d8" name="GAD-7" />
            <Line type="monotone" dataKey="phq9" stroke="#82ca9d" name="PHQ-9" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <button onClick={onNewQuestionnaire}>New Questionnaire</button>
    </div>
  );
};

export default History;