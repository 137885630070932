// src/utils.js
export const getGAD7Severity = (score) => {
    if (score >= 15) return 'Severe anxiety';
    if (score >= 10) return 'Moderate anxiety';
    if (score >= 5) return 'Mild anxiety';
    return 'Minimal anxiety';
  };
  
  export const getPHQ9Severity = (score) => {
    if (score >= 20) return 'Severe depression';
    if (score >= 15) return 'Moderately severe depression';
    if (score >= 10) return 'Moderate depression';
    if (score >= 5) return 'Mild depression';
    return 'Minimal depression';
  };
  
  export const saveData = (data) => {
    localStorage.setItem('mentalHealthData', JSON.stringify(data));
  };
  
  export const loadData = () => {
    const data = localStorage.getItem('mentalHealthData');
    return data ? JSON.parse(data) : [];
  };