import React, { useState } from 'react';
import { getGAD7Severity, getPHQ9Severity } from '../utils';

const gad7Questions = [
  { id: 'gad1', text: 'Feeling nervous, anxious, or on edge' },
  { id: 'gad2', text: 'Not being able to stop or control worrying' },
  { id: 'gad3', text: 'Worrying too much about different things' },
  { id: 'gad4', text: 'Trouble relaxing' },
  { id: 'gad5', text: 'Being so restless that it\'s hard to sit still' },
  { id: 'gad6', text: 'Becoming easily annoyed or irritable' },
  { id: 'gad7', text: 'Feeling afraid as if something awful might happen' },
];

const phq9Questions = [
  { id: 'phq1', text: 'Little interest or pleasure in doing things' },
  { id: 'phq2', text: 'Feeling down, depressed, or hopeless' },
  { id: 'phq3', text: 'Trouble falling or staying asleep, or sleeping too much' },
  { id: 'phq4', text: 'Feeling tired or having little energy' },
  { id: 'phq5', text: 'Poor appetite or overeating' },
  { id: 'phq6', text: 'Feeling bad about yourself or that you are a failure or have let yourself or your family down' },
  { id: 'phq7', text: 'Trouble concentrating on things, such as reading the newspaper or watching television' },
  { id: 'phq8', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual' },
  { id: 'phq9', text: 'Thoughts that you would be better off dead, or of hurting yourself' },
];

const answerOptions = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

const Results = ({ results, onViewHistory, onNewQuestionnaire }) => {
  const [showRawResults, setShowRawResults] = useState(false);
  const [showDetailedResults, setShowDetailedResults] = useState(true);

  console.log("Results data:", results);

  // eslint-disable-next-line no-unused-vars
  const formatEmailBody = () => {
    if (!results || !results.answers) {
      return "Error: No results data available.";
    }

    const date = results.date ? new Date(results.date).toLocaleDateString() : "Date not available";
    let emailBody = `Mental Health Questionnaire Results - ${date}\n\n`;

    emailBody += "Dear Healthcare Professional,\n\n";
    emailBody += "Please find below the results of a mental health self-assessment completed by your patient using the Mental Health Tracker app. This assessment includes the GAD-7 (Generalized Anxiety Disorder-7) and PHQ-9 (Patient Health Questionnaire-9) scales.\n\n";

    // GAD-7 Section
    emailBody += "GAD-7 (Generalized Anxiety Disorder-7) Assessment:\n";
    emailBody += "======================================================\n\n";
    gad7Questions.forEach((question, index) => {
      const score = results.answers[question.id];
      const answer = answerOptions[score];
      emailBody += `${index + 1}. ${question.text}\n   Answer: ${answer} (Score: ${score})\n\n`;
    });
    emailBody += `Total GAD-7 Score: ${results.gad7}\n`;
    emailBody += `Assessment: ${getGAD7Severity(results.gad7)}\n\n`;

    // PHQ-9 Section
    emailBody += "PHQ-9 (Patient Health Questionnaire-9) Assessment:\n";
    emailBody += "======================================================\n\n";
    phq9Questions.forEach((question, index) => {
      const score = results.answers[question.id];
      const answer = answerOptions[score];
      emailBody += `${index + 1}. ${question.text}\n   Answer: ${answer} (Score: ${score})\n\n`;
    });
    emailBody += `Total PHQ-9 Score: ${results.phq9}\n`;
    emailBody += `Assessment: ${getPHQ9Severity(results.phq9)}\n\n`;

    emailBody += "Please note that this self-assessment tool is not a substitute for professional diagnosis. The results should be reviewed and interpreted in the context of a comprehensive clinical evaluation.\n\n";

    emailBody += "This email was generated by the Mental Health Tracker app. If you have any questions about this report, please contact the patient directly.\n\n";

    emailBody += "Thank you for your attention to this matter.";

    return emailBody.trim();
  };

  const handleEmailResults = () => {
    const subject = encodeURIComponent('Mental Health Questionnaire Results');
    const body = encodeURIComponent(formatEmailBody());
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const renderQuestionTable = (questions, type) => {
    return (
      <table className="results-table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => {
            const score = results.answers ? results.answers[question.id] : undefined;
            const answer = score !== undefined ? answerOptions[score] : 'No answer';
            return (
              <tr key={question.id}>
                <td>{index + 1}. {question.text}</td>
                <td>{answer}</td>
                <td>{score !== undefined ? score : 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const generateResultsSummary = () => {
    if (!results || !results.answers) {
      return "No results available.";
    }

    const date = results.date ? new Date(results.date).toLocaleDateString() : "Date not available";
    let summary = `Mental Health Questionnaire Results - ${date}\n\n`;
    summary += `GAD-7 Score: ${results.gad7} - ${getGAD7Severity(results.gad7)}\n`;
    summary += `PHQ-9 Score: ${results.phq9} - ${getPHQ9Severity(results.phq9)}\n`;
    return summary;
  };

  const handleCopyResults = () => {
    const summary = generateResultsSummary();
    navigator.clipboard.writeText(summary).then(() => {
      alert('Results summary copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      alert('Failed to copy results. Please try again.');
    });
  };

  return (
    <div className="results-container">
      <h2>Your Results</h2>
      {results && results.answers && (
        <>
          <div className="score">GAD-7 Score: {results.gad7} - {getGAD7Severity(results.gad7)}</div>
          <div className="score">PHQ-9 Score: {results.phq9} - {getPHQ9Severity(results.phq9)}</div>

          <button onClick={() => setShowDetailedResults(!showDetailedResults)}>
            {showDetailedResults ? 'Hide Detailed Results' : 'Show Detailed Results'}
          </button>

          {showDetailedResults && (
            <>
              <h3>GAD-7 Detailed Results:</h3>
              {renderQuestionTable(gad7Questions, 'gad7')}

              <h3>PHQ-9 Detailed Results:</h3>
              {renderQuestionTable(phq9Questions, 'phq9')}
            </>
          )}

          <button onClick={() => setShowRawResults(!showRawResults)}>
            {showRawResults ? 'Hide Raw Results' : 'Show Raw Results'}
          </button>

          {showRawResults && (
            <div className="raw-results">
              <h3>Raw Results Object:</h3>
              <pre>{JSON.stringify(results, null, 2)}</pre>
            </div>
          )}
        </>
      )}
      <div className="action-buttons">
        <button onClick={onViewHistory}>View History</button>
        <button onClick={onNewQuestionnaire}>New Questionnaire</button>
        <button onClick={handleEmailResults}>Email Results to Professional</button>
        <button onClick={handleCopyResults}>Copy Results Summary</button>
      </div>
    </div>
  );
};

export default Results;