// src/components/IntroPage.js
import React from 'react';

const IntroPage = ({ onStartQuestionnaire }) => {
  return (
    <div className="intro-container">
      <h1>Welcome to Mental Health Tracker</h1>
      <p>This app helps you monitor your mental health using two standardized questionnaires:</p>
      <ul>
        <li>GAD-7 (Generalized Anxiety Disorder-7)</li>
        <li>PHQ-9 (Patient Health Questionnaire-9)</li>
      </ul>
      <p>The questionnaire will take about 5-10 minutes to complete.</p>
      <p>Regular check-ins can help you track your mental health over time and identify patterns or changes.</p>
      <p>Are you ready to begin?</p>
      <button onClick={onStartQuestionnaire}>Start Questionnaire</button>
    </div>
  );
};

export default IntroPage;