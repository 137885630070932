const questions = [
  { id: 'gad1', text: 'Feeling nervous, anxious, or on edge', type: 'gad7' },
  { id: 'gad2', text: 'Not being able to stop or control worrying', type: 'gad7' },
  { id: 'gad3', text: 'Worrying too much about different things', type: 'gad7' },
  { id: 'gad4', text: 'Trouble relaxing', type: 'gad7' },
  { id: 'gad5', text: 'Being so restless that it\'s hard to sit still', type: 'gad7' },
  { id: 'gad6', text: 'Becoming easily annoyed or irritable', type: 'gad7' },
  { id: 'gad7', text: 'Feeling afraid as if something awful might happen', type: 'gad7' },
  { id: 'phq1', text: 'Little interest or pleasure in doing things', type: 'phq9' },
  { id: 'phq2', text: 'Feeling down, depressed, or hopeless', type: 'phq9' },
  { id: 'phq3', text: 'Trouble falling or staying asleep, or sleeping too much', type: 'phq9' },
  { id: 'phq4', text: 'Feeling tired or having little energy', type: 'phq9' },
  { id: 'phq5', text: 'Poor appetite or overeating', type: 'phq9' },
  { id: 'phq6', text: 'Feeling bad about yourself or that you are a failure or have let yourself or your family down', type: 'phq9' },
  { id: 'phq7', text: 'Trouble concentrating on things, such as reading the newspaper or watching television', type: 'phq9' },
  { id: 'phq8', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual', type: 'phq9' },
  { id: 'phq9', text: 'Thoughts that you would be better off dead, or of hurting yourself', type: 'phq9' },
];

const answerOptions = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

export const generateEmailContent = (results) => {
  const { answers, date } = results;
  
  let emailContent = `Mental Health Questionnaire Results - ${date}\n\n`;

  emailContent += 'GAD-7 (Generalized Anxiety Disorder-7) Assessment:\n';
  emailContent += '======================================================\n\n';

  let calculatedGad7 = 0;
  for (let i = 1; i <= 7; i++) {
    const questionId = `gad${i}`;
    const question = questions.find(q => q.id === questionId);
    const score = answers[questionId];
    calculatedGad7 += score;
    emailContent += `${i}. ${question.text}\n`;
    emailContent += `Answer: ${answerOptions[score]} (Score: ${score})\n\n`;
  }

  emailContent += `Total GAD-7 Score: ${calculatedGad7}\n`;
  emailContent += `Assessment: ${getGAD7Assessment(calculatedGad7)}\n\n`;

  emailContent += 'PHQ-9 (Patient Health Questionnaire-9) Assessment:\n';
  emailContent += '======================================================\n\n';

  let calculatedPhq9 = 0;
  for (let i = 1; i <= 9; i++) {
    const questionId = `phq${i}`;
    const question = questions.find(q => q.id === questionId);
    const score = answers[questionId];
    calculatedPhq9 += score;
    emailContent += `${i}. ${question.text}\n`;
    emailContent += `Answer: ${answerOptions[score]} (Score: ${score})\n\n`;
  }

  emailContent += `Total PHQ-9 Score: ${calculatedPhq9}\n`;
  emailContent += `Assessment: ${getPHQ9Assessment(calculatedPhq9)}\n\n`;

  emailContent += 'Please note that this self-assessment tool is not a substitute for professional diagnosis. The results should be reviewed and interpreted in the context of a comprehensive clinical evaluation.\n\n';

  return emailContent;
};

const getGAD7Assessment = (score) => {
  if (score >= 15) return 'Severe anxiety';
  if (score >= 10) return 'Moderate anxiety';
  if (score >= 5) return 'Mild anxiety';
  return 'Minimal anxiety';
};

const getPHQ9Assessment = (score) => {
  if (score >= 20) return 'Severe depression';
  if (score >= 15) return 'Moderately severe depression';
  if (score >= 10) return 'Moderate depression';
  if (score >= 5) return 'Mild depression';
  return 'Minimal depression';
};